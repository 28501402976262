import logo from './logo.svg';
import './App.css';
import Stack from '@material-ui/core/Stack';
import React, { useState } from 'react';
// import * as React from 'react';
import Box from '@material-ui/core/Box';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import HomeIcon from '@material-ui/icons/Home';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { styled } from '@material-ui/core/styles';

const Input = styled('input')({
  display: 'none',
});

function getLocation() {
  navigator.geolocation.getCurrentPosition(position => {
    console.log(position.coords)
  }, error => {
    console.error(error)
    alert('No Permission For Grab Location')
  })
}


function App() {
  const [value, setValue] = React.useState(0);

  return (
    <Stack className="App"
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Stack item xs={12}>
        <img src={logo} className="App-logo" alt="Tech Krafters Inc Logo - Custom Software Development" />
      </Stack>
      <Stack item xs={12}>
        <h1 className="center-align" title="Custom Software Development">Custom Software Development</h1>
        <p className="flow-text center-align">Building Custom Software Since 1999.</p>
        <p className="flow-text center-align">We seen it all.</p>
      </Stack>
      {/* <Stack>
        <input accept="image/*" id="icon-button-file" type="file" capture="environment" />
      </Stack> */}
      {/* <Stack direction="row" spacing={2}>
        <label htmlFor="icon-button-file">
          <Input accept="image/*" id="icon-button-file" type="file" capture="environment" />
          <Button variant="contained" component="span">
            <CameraAltIcon sx={{ fontSize: 80, color: '#E0E0E0' }} />
          </Button>
        </label>
        <Button variant="contained" component="span"
          onClick={ () => { alert('Hey!, You are already home, whats up dude?') }}
        >
          <HomeIcon sx={{ fontSize: 80, color: '#E0E0E0' }} />
        </Button>
          
        
        <Button variant="contained" component="span"
          onClick={() => { getLocation() }}
        >
          <LocationOnIcon sx={{ fontSize: 80, color: '#E0E0E0' }} />
        </Button>
      </Stack> */}

    </Stack>
  );
}

export default App;
